//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import '../styles/style.scss'

// Importation des nodes modules __________
import $ from 'jquery'
window.jQuery = $
window.$ = $
import 'jquery.easing'
import 'jquery-validation'
import 'slick-carousel'
//import 'slick-carousel/slick/slick-theme.scss'
//import 'slick-carousel/slick/slick.scss'
import 'svg4everybody'
import 'jquery-mask-plugin'
import 'chosen-js'
import '@fancyapps/fancybox/dist/jquery.fancybox'

// Importation des scripts __________
import './plugins/addClassOnScroll.js'
import './plugins/parallax.js'
import './plugins/smoothScroll.js'
import './plugins/toggleHtmlClass.js'
import './plugins/scrollfire.js'
import './transition.js'
import './global.js'
