( function( $ ) {
	
	$.fn.BLK_addClassOnScroll = function( options ) {
		
		// VARIABLES __________
		var settings = $.extend({
			elements: [/*{
				element: '#monElement1',
				classe: 'a',
				fixPosition: 500,
			}, {
				element: '#monElement2',
				classe: 'b',
				fixPosition: 250,
			}*/]
		}, options);
		
		var self = this;
		var scrollPosition = $( document ).scrollTop();
		var prochainePosition = 0;
		
		
		// FONCTIONS __________
		self.destroy = function() {
			$(document).off('scroll');
		}
		
		
		// EXÉCUTION __________
		$( document ).on( 'scroll', function() {
			scrollPosition = $( document ).scrollTop();
			$( settings.elements ).each( function( elementIndex ) {
				
				// Si le prochain élément du tableau est undefined, la prochaine position de la hauteur du document sinon la position du
				// prochain bloc
				if( typeof settings.elements[elementIndex + 1] === 'undefined')
					prochainePosition = $( document ).height();
				else
					prochainePosition = $( settings.elements[ elementIndex + 1 ].element ).offset().top - settings.elements[ elementIndex + 1 ].fixPosition
				
				// Si la position du scroll est plus petite que le premier bloc, retrait de toute les classes de background color
				if( scrollPosition <= $( settings.elements[0].element ).offset().top - settings.elements[0].fixPosition) {
					if( $( self ).hasClass( settings.elements[0].classe ) ){
						$( settings.elements ).each( function( colorIndex ) {
							$( self ).removeClass( settings.elements[ colorIndex ].classe );
						});
					}
				}
				
				// Si on se trouve dans la position d'un bloc, on retire toutes les mauvaises classes de background et on ajoute la bonne
				if( scrollPosition >= $( settings.elements[ elementIndex ].element ).offset().top - settings.elements[ elementIndex ].fixPosition && scrollPosition <= prochainePosition ) {
					if( !$( self ).hasClass( settings.elements[ elementIndex ].classe ) ) {
						$( settings.elements ).each( function( colorIndex ) {
							$( self ).removeClass( settings.elements[ colorIndex ].classe );
						});
						$( self ).addClass( settings.elements[ elementIndex ].classe );
					}
				}
			});
		});
		
		return this;
	}
	
}( jQuery ));