//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Stickyfill from 'stickyfilljs'
//-----------------------------------------------------------------------------------------------------------------

// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    if(e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  for(var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}


// Fonction pour les positions sticky dans IE
export function magicSticky() {
  var elements = document.querySelectorAll('.sticky')
  Stickyfill.add(elements)
}


// Fonction initialisant les tiroirs
export function tiroirs () {
  $('.tiroirs > li .toggleTiroir').on( 'click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.tiroirs li.open .tiroir').animate( {height: 'hide'}, {duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.tiroirs li.open').removeClass('open')
    if ( !ouvert ) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') } , 0)
      $('.tiroir', $(this).parent()).stop(true, false).animate( {height: 'show'}, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock (depart, destination, duration, ajustement){
  // Paramètres optionnels
  duration   = (typeof duration   === 'undefined') ? 600 : duration
  ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

  // Défilement au clique
  $(depart).on( 'click', function(){
    if($(window).width() <= 1024) ajustement = 0
    $.fn.BLK_smoothScroll.destroy()
    $('html, body').animate({
      scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
    }, duration, 'easeInOutExpo',
    function() {
      $.fn.BLK_smoothScroll()
  	})
  })
}


// Fonction gérant le format du header selon le scroll
export function scrollForHeader() {
  $(document).on('scroll', function() {
    if($(document).scrollTop() > 10) {
      $('header').addClass('notOnTop')
    } else {
      $('header').removeClass('notOnTop')
    }
  })
}


export function backToTopBtn() {
  $(document).on('scroll', function() {
    if(($(window).scrollTop() + $(window).height()) >= $('footer').offset().top) {
      $('#backToTop').addClass('getAway')
    } else if($(window).scrollTop() <= 50) {
      $('#backToTop').addClass('getAway')
    } else {
      $('#backToTop').removeClass('getAway')
    }
  })
}


// Retourne si le site est ouvert dans un appareil mobile
export function isMobile() {
  let condition = false

  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    condition = true
  }

  return condition
}
