(function($) {

  $.fn.BLK_parallax = function(options) {

    // VARIABLES __________
    var settings = $.extend({
      elements: [/*{          ---> Le tableau d'objet contenant les éléments parallax
        element: '#element1', ---> L'élément parallax
      	type: 'element',      ---> Détermine si le parallax est appliqué à un 'element' ou à un 'background'
    		intensityX: 0.1,      ---> L'intensité en chiffre à virgule positif ou négatif déterminant la direction en X
    		intensityY: 0.1,      ---> L'intensité en chiffre à virgule positif ou négatif déterminant la direction en Y
      	breakpoint: 1024,     ---> Le breakpoint auquel l'élément ne sera plus parallax
    		offsetX: 0,           ---> L'offset en X pour modifier la position de l'élément
    		offsetY: 0,           ---> L'offset en Y pour modifier la position de l'élément
        directionX: 'left',   ---> Détermine si c'est le left ou right
      	directionY: 'top',    ---> Détermine si c'est le top ou bottom
      	notInFirefox: false   ---> Détermine si le parallax va prendre effet sur Firefox (background fixed avec un transform ne fonctionne pas)
    		notInEdge: false      ---> Détermine si le parallax va prendre effet sur Edge (background fixed ne fonctionne pas)
      }*/]
    }, options)
    var self = this
    var element, type, intensityX, intensityY, offsetX, offsetY, directionX, directionY, breakpoint
    var isFirefox = typeof InstallTrigger !== 'undefined'
    var isIE = false || !!document.documentMode;
    var isEdge = !isIE && !!window.StyleMedia

    // Test l'existence des éléments
    for (var index = settings.elements.length - 1; index >= 0; index--) {
      element = settings.elements[index].element;
      if (!$(element).length) {
        settings.elements.splice(index, 1);
      }
    }

    // Détermine les valeurs par défaut de chaque
    $(settings.elements).each(function(index) {
      if (settings.elements[index].type == undefined) settings.elements[index].type = 'element'
      if (settings.elements[index].intensityX == undefined) settings.elements[index].intensityX = 0
      if (settings.elements[index].intensityY == undefined) settings.elements[index].intensityY = 0
      if (settings.elements[index].breakpoint == undefined) settings.elements[index].breakpoint = 1400
      if (settings.elements[index].offsetX == undefined) settings.elements[index].offsetX = 0
      if (settings.elements[index].offsetY == undefined) settings.elements[index].offsetY = 0
      if (settings.elements[index].directionX == undefined) settings.elements[index].directionX = 'left'
      if (settings.elements[index].directionY == undefined) settings.elements[index].directionY = 'top'
      if (settings.elements[index].notInFirefox == undefined) settings.elements[index].notInFirefox = false
      if (settings.elements[index].notInEdge == undefined) settings.elements[index].notInEdge = false
    })



    // FONCTIONS __________
    // Détruire le parallax
    $.fn.BLK_parallax.destroy = function() {
      $(document).off('scroll')
      $(window).off('resize')
      return this
    }

    // Retourne les raccourcis de chaque élément parallax
    function getRaccourcis(index) {
      breakpoint = settings.elements[index].breakpoint
      element = settings.elements[index].element
      type = settings.elements[index].type
      intensityX = settings.elements[index].intensityX
      intensityY = settings.elements[index].intensityY
      offsetX = settings.elements[index].offsetX
      offsetY = settings.elements[index].offsetY
      directionX = settings.elements[index].directionX
      directionY = settings.elements[index].directionY
      notInFirefox = settings.elements[index].notInFirefox
      notInEdge = settings.elements[index].notInEdge
    }

    // Fonction exécutant l'équation créant le parallax
    function equation(paramElement, paramType, paramIntensityX, paramIntensityY, paramOffsetX, paramOffsetY, paramDirectionX, paramDirectionY) {
      // L'offset de l'élément sauvegardé en data
      var elementOffset = $(paramElement).data('initY')
      // Le centre du viewport
      var centreEcran = $(document).scrollTop() + ($(window).height() / 2)
      // Le centre de l'élément
      var centreElement = elementOffset + ($(paramElement).height() / 2)
      // La position de l'élément est au centre du viewport
      var position = centreEcran - centreElement
      // Le calcul pour le parallax appliqué à un élément en X ou Y ou en background en X ou Y
      if (paramType == 'element') {
        if (paramIntensityY != 0) $(paramElement).css(paramDirectionY, (position * paramIntensityY) + paramOffsetY + 'px')
        if (paramIntensityX != 0) $(paramElement).css(paramDirectionX, (position * paramIntensityX) + paramOffsetX + 'px')
      } else {
        if (paramIntensityY != 0) $(paramElement).css('background-position-y', ((position * paramIntensityY) + paramOffsetY) + 'px')
        if (paramIntensityX != 0) $(paramElement).css('background-position-x', ((position * paramIntensityX) + paramOffsetX) + 'px')
      }
    }

    // Permet de réinitialiser le parallax
    function removeParallax() {
      if (type == 'element') {
        if (intensityX != 0) $(element).css(directionX, '')
        if (intensityY != 0) {
          $(element).css(directionY, '')
          $(element).css('margin-' + directionY, '')
        }
      } else {
        $(element).css('background-position-x', 'center')
        $(element).css('background-position-y', 'center')
      }
    }

    // Permet de gérer si l'exécution du parallax va avoir lieu ou pas
    function gestionExecution(element, notInFirefox, notInEdge, execution) {
      if (isFirefox && !notInFirefox) execution()
      if (isEdge && !notInEdge) execution()
      if (!isFirefox && !notInFirefox) execution()
      if (isFirefox && !notInFirefox) $(element).css('background-attachment', 'scroll')
      if (isEdge && !notInEdge) $(element).css('background-attachment', 'scroll')
    }

    // Détermine si la position de l'élément est absolute, si c'est le cas, le offset de l'élément sera transférer
    // à son margin pour éviter des problème de positionnement
    function positionAbsolute(paramElement, paramDirectionY) {
      if ($(paramElement).css('position') == 'absolute' || $(paramElement).css('position') == 'fixed') {
        var marginY = parseInt($(paramElement).css('margin-' + directionY))
        var positionY = parseInt($(paramElement).css(directionY))
        if (paramDirectionY == 'top') {
          $(element).css({ directionY: 0, 'margin-top': marginY + positionY + 'px' })
        } else {
          $(element).css({ directionY: 0, 'margin-bottom': marginY + positionY + 'px' })
        }
      }
    }



    // EXÉCUTION __________
    // Positionnement des éléments au départ
    $(settings.elements).each(function(index) {
      getRaccourcis(index)

      var execution = function() {
        // Si le viewport est plus grand que le breakpoint
        if ($(window).outerWidth() > breakpoint) {
          $(element).data('initY', $(element).offset().top)
          if (type == 'element') positionAbsolute(element, directionY)
          equation(element, type, intensityX, intensityY, offsetX, offsetY, directionX, directionY)
        } else {
          removeParallax()
        }
      }

      // Pour des raisons spécifique on ne veut peut-être pas de parallax dans
      // ces navigateurs tout dépendant du contexte pour éviter des bugs
      gestionExecution(element, notInFirefox, notInEdge, execution)
    })

    // Selon le redimensionnement de la fenêtre, le parallax arrêtera ou reprendra et l'élément sera positionné en conséquence
    $(window).on('resize', function() {
      $(settings.elements).each(function(index) {
        getRaccourcis(index)

        var execution = function() {
          if ($(window).outerWidth() > breakpoint) {
            removeParallax()
            $(element).data('initY', $(element).offset().top) // Prendre le offset de l'élément après le redimensionnement
            if (type == 'element') positionAbsolute(element, directionY)
            equation(element, type, intensityX, intensityY, offsetX, offsetY, directionX, directionY)
          } else {
            removeParallax()
          }
        }

        gestionExecution(element, notInFirefox, notInEdge, execution)
      })
    })

    // La position sera modifiée en fonction de l'intensité à chaque fois que l'utilisateur scroll
    $(document).on('scroll', function() {
      $(settings.elements).each(function(index) {
        getRaccourcis(index)

        var execution = function() {
          if ($(window).outerWidth() > breakpoint && $(window).scrollTop() != 0)
            equation(element, type, intensityX, intensityY, offsetX, offsetY, directionX, directionY)
        }

        gestionExecution(element, notInFirefox, notInEdge, execution)
      })
    })

    return this
  }

}(jQuery))
