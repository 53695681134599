//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { isMobile } from "./fonctions";
//-----------------------------------------------------------------------------------------------------------------

// LES FONCTIONS
// Transforme les selects
export function magicSelect() {
	$(".chosen-select").chosen({ disable_search: true })
}


// Fonction qui gère le label des input
export function fixInput() {
	// Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
	$('input').focusin(function(){ $(this).removeClass('pasVide') })

	// Si le champ est vide, on replace le label à sa position d'origine au focusout
	$('input').focusout(function(){
		($(this).val() != '' ? $(this).addClass('pasVide') : $(this).removeClass('pasVide'))
	})
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function selectFilter() {
  const themePath = window.config.theme_path;

  if (!isMobile()) {
    // eslint-disable-next-line no-undef
    tail.select('select', { animate: false });
  }

  // Ajouter les images dans les options du Tail Select
  const selectBoxes = document.querySelectorAll('.js-select-filter .tail-select');
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (let i = 0; i < selectBoxesLength; i += 1) {
    // Ajouter une classe qui permet d'éviter le smoothScroll
    const tailSelectDropdownInner = selectBoxes[i].querySelector('.dropdown-inner')
    tailSelectDropdownInner.classList.add('innerscroll')

    // Ajouter l'icone en symbole
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useElem.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `/themes/${themePath}/assets/images/icones/symbols.svg#ico-select-filter-pointer`,
    );
    svgElem.appendChild(useElem);
    selectBoxes[i].appendChild(svgElem);
  }
}


// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  return {
    required: 'Ce champ est obligatoire.',
    email:    'Veuillez fournir une adresse électronique valide.',
    phone:    'Veuillez fournir un numéro de téléphone valide.',
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  jQuery.validator.addMethod("courriel", function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  })
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({ignore:[]})
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement : 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr("type") == "radio") {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr("type") == "checkbox") {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function(form) {
      if(typeof handler != 'undefined') eval(handler + '()')
      return true
    }
  })
  $(submit).click( function() { $(form).submit() })
}

// Validation du formulaire de contact
export function formContact(form, submit) {
    validationEmail()
    var m = getMessages()
    formatPhone('#contactTelephone')
    var rules = {
      nom:       { required: true },
      prenom:    { required: true },
      courriel:  { required: true, courriel: true },
      telephone: { required: true, minlength: 14  },
      message:   { required: true }
    }
    var messages = {
      nom:       { required: m.required },
      prenom:    { required: m.required },
      telephone: { required: m.required, minlength: m.phone },
      courriel:  { required: m.required, courriel: m.email  } ,
      message:   { required: m.required }
    }
    formValidation(form, submit, rules, messages, 'handleContact')
}

// Validation du formulaire de l'infolettre
export function formInfolettre(form, submit) {
    validationEmail()
    var m = getMessages()
    var rules =    { courriel: { required: true,       courriel: true    }}
    var messages = { courriel: { required: m.required, courriel: m.email }}
    formValidation(form, submit, rules, messages)
}



// LES HANDLER __________
// Handle le formulaire de contact pour l'envoi
function handleContact() {
  $.request('courrielator::onFormSubmit', {
    loading: $.oc.stripeLoadIndicator,
    data: {
      nom:       $('#contactNom').val(),
      prenom:    $('#contactPrenom').val(),
      telephone: $('#contactTelephone').val(),
      courriel:  $('#contactCourriel').val(),
      message:   $('#contactMessage').val(),
    },
    complete: function (output) {
      var response = output.responseJSON
    }
  })
}
