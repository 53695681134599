( function( $ ) {

	$.fn.BLK_toggleHtmlClass = function( options ) {

		// VARIABLES __________
		var settings = $.extend({
			target: 'html',                // L'élément qui va recevoir la classe
			htmlClass: '',                 // La classe qui sera ajouté au HTML
			close: '.closeOverlay',        // La classe permettant de retirer la classe sur le HTML
			noScroll: true,                // Retirer le scroll sur le body pendant que la classe est sur le HTML
			keepNoScroll: 'keepNoScroll',  // Garder le noScroll sur le body lors du retrait de la classe sur le HTML
			backToTop: false,              // Retourne en haut de la page à l'ajout de la classe sur le HTML
		}, options)
		var self = this



		// FONCTIONS __________
		// Fonction empêchant le scroll sur le body lorsqu'elle est appelé
		function addNoScroll() {
			if(settings.backToTop) $(document).scrollTop(0)
			if ($(document).height() > $(window).height()) {
				var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
				$('html').addClass('noscroll').css('top',-scrollTop)
			}
		}

		// Fonction rétablissant le scroll sur le body lorsqu'elle est appelé
		function removeNoScroll() {
			if(settings.backToTop) {
				$('html').removeClass('noscroll')
				$('html,body').scrollTop(0)
			} else {
				var scrollPosition = parseInt($('html').css('top'))
				$('html').removeClass('noscroll')
				$('html,body').scrollTop(-scrollPosition)
			}
		}



		// EXÉCUTION __________
		// Permet d'ajouter une classe au target
		$(self).on('click', function(){
			if(!$(settings.target).hasClass(settings.htmlClass)) {
				$(settings.target).addClass(settings.htmlClass)
				if(settings.noScroll) addNoScroll()
			} else {
				$(settings.target).removeClass(settings.htmlClass)
				if(settings.noScroll) removeNoScroll()
			}
		})

		// Permet de retirer une classe au target
		$(settings.close).on('click', function(e){
      if($(settings.target).hasClass(settings.htmlClass)) {
        $(settings.target).removeClass(settings.htmlClass)
        if(!$(this).hasClass(settings.keepNoScroll)) {
          if(settings.noScroll) removeNoScroll()
        }
      }
		})

		return this
	}

}( jQuery ))
