(function($) {

  $.fn.BLK_smoothScroll = function(options) {

    // VARIABLES __________
    var settings = $.extend({
      innerscrollClass: '.innerscroll', // --> Classe à ajouter sur un élément ayant un innerscroll
      frameRate: 1000 / 60,             // --> Le taux de rafraichissement
      vitesseAnimation: 12,             // --> La vitesse d'animation
      vitesseDefilement: 60,            // --> La vitesse de défilement
    }, options)
    var self = this
    var date = new Date()




    // FONCTIONS __________
    $.fn.BLK_smoothScroll.destroy = function() {
      window.removeEventListener('DOMMouseScroll', wheel)
      clearInterval(interval)
    }



    // EXÉCUTION __________
    if (window.addEventListener) window.addEventListener('DOMMouseScroll', wheel, false)
    window.onmousewheel = document.onmousewheel = wheel

    function wheel(event) {
      // Gestion des différents innerscroll du site
      var target = event.target
      target = target && $(target).parents(settings.innerscrollClass)[0]
      if (target) return true

      // Gestion de la limite de vitesse du scroll
      if (event.preventDefault) event.preventDefault()
      if (new Date() - date < 10) return
      date = new Date()

      var delta = 0
      if (event.wheelDelta) {
        delta = event.wheelDelta / 120
      } else if (event.detail) {
        delta = -event.detail / 3
      }

      if (delta > 3) delta = 3
      if (delta < -3) delta = -3
      handle(delta)
      event.returnValue = false
    }

    var goUp = true
    var end = null
    var interval = null

    function handle(delta) {
      var animationInterval = settings.frameRate // Taux de rafraichissement
      var scrollSpeed = settings.vitesseAnimation // Vitesse d'animation
      var offSetSpeed = settings.vitesseDefilement // Vitesse de défilement

      if (end == null) end = $(window).scrollTop()
      end -= offSetSpeed * delta
      goUp = delta > 0
      if (interval == null) {
        interval = setInterval(function() {
          var scrollTop = $(window).scrollTop()
          var step = Math.round((end - scrollTop) / scrollSpeed)
          if (scrollTop <= 0 && goUp || scrollTop >= $(document).height() - $(window).height() && !goUp || goUp && step > -1 || !goUp && step < 1) {
            clearInterval(interval)
            interval = null
            end = null
          }
          $(window).scrollTop(scrollTop + step)
        }, animationInterval)
      }
    }

    return this
  }

}(jQuery))
