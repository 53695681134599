//------------------\\
//--- Google map ---\\
//------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve la ou les google map
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Fonction créanmt la mapbox et toutes ses composantes
export function mapbox (element, style, lat, lng, zoom) {
  zoom = (typeof zoom === 'undefined') ? 13 : zoom

  // Création de la carte
  mapboxgl.accessToken = 'pk.eyJ1IjoiYmxhbmtvLW1hcCIsImEiOiJjam11azBodnQwMWR6M2ttMGlvemZ2eGsyIn0.w2E6jbW7l-KJdhe3UB86fg'
  var map = new mapboxgl.Map({
    container: element,
    style: 'mapbox://styles/' + style,
    center: [lat, lng],
    zoom: zoom
  })

  // Création du popup
  var popup = new mapboxgl.Popup({ }).setText('740, rue Pontbriand, Repentigny (Québec) J6A 8E1')

  // Création du html contenant le marqueur
  var marker = document.createElement('div')
  marker.id = 'marker'

  // Un nouveau marqueur
  new mapboxgl.Marker(marker).setLngLat([lat, lng]).setPopup(popup).addTo(map)

  // Ajout du panneau de control
  map.addControl(new mapboxgl.NavigationControl())

  // Désactivation du scroll
  map.scrollZoom.disable()
}
