//-----------------------------\\
//--- Transition avec barba ---\\
//-----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'

import {
  initDemo,
  leaveDemo,
  init,
  leave,
  initAccueil,
  leaveAccueil,
  initSalle,
  leaveSalle,
  initForfait,
  leaveForfait,
  initPromotions,
  leavePromotions,
  initActualites,
  leaveActualites,
  initContact,
  leaveContact,
  initGeneric,
  leaveGeneric,
  initEvenement,
  leaveEvenement,
  initEvenements,
  leaveEvenements,
  initGalleriesMediasView,
  leaveGalleriesMediasView,
  initGalleryMediasView,
  leaveGalleryMediasView,
  backendAccessBton,
  activeFilter,
  changeSelect,
  galleriesLoaded
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------

window.activeFilter = activeFilter
window.galleriesLoaded = galleriesLoaded

doOnce()

function doOnce() {
  $(document).ready(function() {
    initPageTransition()
    backendAccessBton()
    var firstloader = new Loader()
    firstloader.reset()
    firstloader.setCompleteCallback([function() {
      setTimeout(function() {
        $('html').removeClass('chargement')
        $.fn.BLK_scrollfire.init()
      }, 600)
    }])
    firstloader.start()
    $(document).scrollTop(0)
    $.fn.BLK_smoothScroll()
  })
}

function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'
  Barba.Dispatcher.on('initStateChange', function(currentStatus) {
    setTransitionLogo(currentStatus.url)
  })
  Barba.Dispatcher.on('linkClicked', function(HTMLElement, MouseEvent) {
    prepareForTransition(HTMLElement, MouseEvent)
  })
  Barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus, container) {})
  setTransition()
  setView()
  Barba.Pjax.start()
}



// ----------------------------------------------------------------------------------------------------------------------------------------------------
// GLOBAL VIEW JS.
// ----------------------------------------------------------------------------------------------------------------------------------------------------
function prepareForTransition( HTMLElement, MouseEvent ) {}

function setTransitionLogo(url) {}

// ----------------------------------------------------------------------------------------------------------------------------------------------------
// SPECIFIC VIEW JS.
// ----------------------------------------------------------------------------------------------------------------------------------------------------
function setView() {

  // ------------------------------ Standart --------------------------------
  var SdtView = Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
    },
    onLeave: function() {
      leave()
    },
    onLeaveCompleted: function() {}
  })
  SdtView.init()

  // ------------------------------ Accueil --------------------------------
  var AccueilView = Barba.BaseView.extend({
    namespace: 'accueilView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initAccueil()
    },
    onLeave: function() {
      leave()
      leaveAccueil()
    },
    onLeaveCompleted: function() {}
  })
  AccueilView.init()

  // ------------------------------ GENERIC --------------------------------
  var SalleView = Barba.BaseView.extend({
    namespace: 'genericView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initGeneric()
    },
    onLeave: function() {
      leave()
      leaveGeneric()
    },
    onLeaveCompleted: function() {}
  })
  SalleView.init()

  // ------------------------------ Salle --------------------------------
  var SalleView = Barba.BaseView.extend({
    namespace: 'salleView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initSalle()
    },
    onLeave: function() {
      leave()
      leaveSalle()
    },
    onLeaveCompleted: function() {}
  })
  SalleView.init()

  // ------------------------------ Forfait --------------------------------
  var ForfaitView = Barba.BaseView.extend({
    namespace: 'forfaitView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initForfait()
    },
    onLeave: function() {
      leave()
      leaveForfait()
    },
    onLeaveCompleted: function() {}
  })
  ForfaitView.init()

  // ------------------------------ Evenements --------------------------------
  Barba.BaseView.extend({
    namespace: 'evenementsView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initEvenements()
    },
    onLeave: function() {
      leave()
      leaveEvenements()
    },
    onLeaveCompleted: function() {}
  }).init()

  // ------------------------------ Detail Evenement --------------------------------
  var EvenementView = Barba.BaseView.extend({
    namespace: 'evenementView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initEvenement()
    },
    onLeave: function() {
      leave()
      leaveEvenement()
    },
    onLeaveCompleted: function() {}
  })
  EvenementView.init()

  // ------------------------------ Promotions --------------------------------
  var PromotionsView = Barba.BaseView.extend({
    namespace: 'promotionsView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initPromotions()
    },
    onLeave: function() {
      leave()
      leavePromotions()
    },
    onLeaveCompleted: function() {}
  })
  PromotionsView.init()

  // ------------------------------ Actualités --------------------------------
  var ActualitesView = Barba.BaseView.extend({
    namespace: 'actualitesView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initActualites()
    },
    onLeave: function() {
      leave()
      leaveActualites()
    },
    onLeaveCompleted: function() {}
  })
  ActualitesView.init()

  // ------------------------------ Galeries de médias --------------------------------
  var GalleriesMediasView = Barba.BaseView.extend({
    namespace: 'galleriesMediasView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initGalleriesMediasView()
      changeSelect('#gallery-year-select')
      changeSelect('#gallery-month-select')
    },
    onLeave: function() {
      leave()
      leaveGalleriesMediasView()
    },
    onLeaveCompleted: function() {}
  })
  GalleriesMediasView.init()

  // ------------------------------ Galerie de médias --------------------------------
  var GalleryMediasView = Barba.BaseView.extend({
    namespace: 'galleryMediasView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initGalleryMediasView()
    },
    onLeave: function() {
      leave()
      leaveGalleryMediasView()
    },
    onLeaveCompleted: function() {}
  })
  GalleryMediasView.init()

  // ------------------------------ Contact --------------------------------
  var ContactView = Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initContact()
    },
    onLeave: function() {
      leave()
      leaveContact()
    },
    onLeaveCompleted: function() {}
  })
  ContactView.init()
}


//---------------------------------------------------------------------------------------------------------------------------------------------
// LOADER
// ----------------------------------------------------------------------------------------------------------------------------------------------------

export function Loader() {
  this.id = Math.random() * 1000
  this.reset()
}

Loader.prototype.setContainer = function(domElmnt) {
  this.targetContainer = domElmnt
}

Loader.prototype.start = function() {
  var self = this
  this.showBar()
  this.theInterval = setInterval(function() {
    self.intervalChecker()
  }, 1)
  this.setLoadList()
  this.loadingCheck()
}

Loader.prototype.reset = function() {
  this.theInterval = null
  this.targetContainer = false
  this.cbComplete = null
  this.imgLoaded = 0
  this.imgToLoad = 0
  this.loadList = null
  this.setLoadingBar()
}

Loader.prototype.setCompleteCallback = function(callbackComplete) {
  this.cbComplete = callbackComplete
}

Loader.prototype.setLoadList = function() {
  var self = this
  if (this.targetContainer) {
    var searchlist = this.setContainer + 'div, ' + this.setContainer + ' img'
  } else {
    var searchlist = 'div, img'
  }

  this.loadList = null
  this.loadList = $(searchlist).filter(function() {

    if ($(this).css("background-image").indexOf("url") > -1) {
      return true
    }
    var attr = $(this).attr('src')
    if (typeof attr !== typeof undefined && attr !== false) {
      return true
    }
  })

  this.imgToLoad = 0
  this.loadList.each(function(i, img) {
    self.imgToLoad++
  })
}

Loader.prototype.loadingCheck = function() {
  var self = this
  var tabUlr = new Array()

  this.loadList.each(function(i, img) {

    if (this.tagName.toLowerCase() != 'img') {

      var regular = new RegExp(/url\(([^()]*)\)/)
      var bi = $(this).css("background-image").match(regular).pop()
      var regReplace = new RegExp('[\'"]', 'gi')
      bi = bi.replace(regReplace, '') // bug IE, Safari, EDGE, Chrome
      if (tabUlr.indexOf(bi) < 0) tabUlr.push(bi)
    } else {
      var imgsrc = $(this).attr('src')
      if (tabUlr.indexOf(imgsrc) < 0) {
        tabUlr.push(imgsrc)
      }
    }
  })

  this.imgToLoad = tabUlr.length

  for (var n = 0; n < tabUlr.length; n++) {
    $('<img/>').on('load', function() {
      $(this).remove()
      self.imgLoaded++
    }).on('error', function() {
      $(this).remove()
      self.imgLoaded++
    }).attr('src', tabUlr[n])
  }
}

Loader.prototype.intervalChecker = function() {
  this.setLoadingBar()
  this.checkForCallback()
}

Loader.prototype.checkForCallback = function() {
  if (this.imgLoaded >= this.imgToLoad) {
    clearTimeout(this.theInterval)
    this.hideBar()
    this.theInterval = null
    for (var i = 0; i < this.cbComplete.length; i++) {
      this.cbComplete[i]()
    }

    // Création de l'événement loadComplete
    $(window).trigger('loadComplete')
  }
}

Loader.prototype.setLoadingBar = function() {
  if (this.imgToLoad > 0) {
    var percent = this.imgLoaded / this.imgToLoad * 100
  } else {
    var percent = 0
  }
  var self = this
  setTimeout(function() {
    $('#loaderbar').css('width', Math.ceil(percent) + '%')
  })
}

Loader.prototype.hideBar = function() {
  $('#loaderbar').fadeOut()
}

Loader.prototype.showBar = function() {
  $('#loaderbar').fadeIn(100)
}

Loader.prototype.autoDestroy = function() {
  for (key in this) {
    this[key] = null
  }
}

Loader.prototype.sleep = function(milliseconds) {
  var start = new Date().getTime()
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds) {
      break
    }
  }
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------
// TRANSITION
// ----------------------------------------------------------------------------------------------------------------------------------------------------
function setTransition() {
  var preloader = new Loader()
  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },
    fadeOut: function() {
      var self = this
      preloader.reset()
      $('.hamburger').removeClass('is-active')
      return $('html').addClass('transition').delay(1500).promise()
    },
    fadeIn: function() {
      var _this = this
      preloader.start()
      if (!window.location.hash) window.scrollTo(0, 0)
      preloader.setCompleteCallback([function() {
        setTimeout(function() {
          $('html').removeClass('transition')
          $.fn.BLK_scrollfire.init()
        }, 300)
        _this.done()
      }])
    }
  })

  Barba.Pjax.getTransition = function() {
    return FadeTransition
  }
}
