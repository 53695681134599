//---------------------------\\
//--- Fonctions des views ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { noSamePageDestination, initScrollFire, tiroirs, scrollToBlock, backToTopBtn, scrollForHeader, magicSticky } from './fonctions.js'
import { closeAllOnResize, overlayMenuMobile, overlayDropDown } from './overlay.js'
import { slickBanniere, destroySlick } from './slicks.js'
import { fixInput, formContact, formInfolettre, selectFilter } from './formulaires.js'
import { mapbox } from './map.js'
//-----------------------------------------------------------------------------------------------------------------

// Initialisation sur toutes les pages
export function init() {
	fixInput()
	closeAllOnResize()
	noSamePageDestination()
  overlayDropDown()
  scrollForHeader()
  overlayMenuMobile()
  magicSticky()
  formInfolettre('#formInfolettre', '#submitInfolettre')
  $.fn.BLK_scrollfire()
  backToTopBtn()
  scrollToBlock('#backToTop', '')
  initShare()
}

// init le script des bouttons de share
export function initShare() {
  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config);
  }

}

export function leave() {

}


// Initialisation de la page d'accueil
export function initAccueil() {
  slickBanniere()
  scrollToBlock('#scrollToNextBlock', '.blockPublications', 600, -100)
  $.fn.BLK_parallax({ elements: [
		{ element: '#forme1', intensityY: 0.1 },
		{ element: '#forme2', intensityY: 0.1, directionY: 'bottom' },
		{ element: '#forme3', intensityY: 0.1 },
	] })
}

export function leaveAccueil() {
  destroySlick('#pageAccueil .slick')
  $.fn.BLK_parallax.destroy()
}



// Initialisation des pages generiques gérées par le plugin PAGES
export function initGeneric() {
  tiroirs()
  $.fn.BLK_parallax({ elements: [{ element: '#forme1', intensityY: 0.1 }] })

}

export function leaveGeneric() {
  $.fn.BLK_parallax.destroy()

}

// Initialisation de la page de salle
export function initSalle() {
  tiroirs()
}

export function leaveSalle() {

}


// Initialisation de la page de forfait
export function initForfait() {
  $.fn.BLK_parallax({ elements: [{ element: '#forme1', intensityY: 0.1 }] })
}

export function leaveForfait() {
  $.fn.BLK_parallax.destroy()
}


// Initialisation de la page de promotions
export function initPromotions() {

}

export function leavePromotions() {

}

// Initialisation de la page des evenements
export function initEvenements() {

}

export function leaveEvenements() {

}

// Initialisation de la page d’evenement
export function initEvenement() {

}

export function leaveEvenement() {

}

// Initialisation de la page d'actualités
export function initActualites() {

}

export function leaveActualites() {

}


// Initialisation de la page de contact
export function initContact() {
  mapbox('mapbox', 'blanko-map/cjmuqrgvmd6lj2stbo2agesm3', -73.4524041, 45.7566927)
	formContact('#formContact', '#submitContact')
  $.fn.BLK_parallax({ elements: [{ element: '#mapbox', intensityY: 0.1 }] })
}

export function leaveContact() {
  $.fn.BLK_parallax.destroy()
}


// Initialisation de la page de galeries de médias
export function initGalleriesMediasView() {
  selectFilter()
}

export function leaveGalleriesMediasView() {

}


// Initialisation de la page d'une galerie de médias
export function initGalleryMediasView() {

}

export function leaveGalleryMediasView() {

}

// Raccourci accès backend
export function backendAccessBton() {
  $('body').keydown( function(e)
  {
    var keycode = (e.keyCode ? e.keyCode : e.which);
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      window.location.href = '/administration';
      return false;
    }
  });
}

export function activeFilter(element) {
  $('.galleries-medias-filters__tag').removeClass('galleries-medias-filters__tag--active')
  $(element).addClass('galleries-medias-filters__tag--active')
}

export function changeSelect(select) {
  $(select).next('.tail-select').find('.dropdown-option').on('click', () => {
    $(select).trigger('change');
  });
}

export function galleriesLoaded(data) {

  if (!data['hasMorePages'])
  {
    $('#moreGalleriesMediasBtn').hide()
  }
  else {
    $('#moreGalleriesMediasBtn').show()
  }
}
