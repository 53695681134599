//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Slick de la bannière d'accueil
export function slickBanniere () {
	$('#pageAccueil .slick').slick({
		arrows: false,
		dots: true,
		appendDots: '#pageAccueil .navPoints',
		infinite: true,
		autoplay: true,
		draggable: false,
		pauseOnHover: false,
		fade: true,
		autoplaySpeed: 20000,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	})
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick (carousel, delai) {
	// Paramètres optionnels
	delai = (typeof delai === 'undefined') ? 1400 : delai

	// Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
	setTimeout(function(){
		$(carousel).slick('unslick')
	}, delai)
}
